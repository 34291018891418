<template>
  <div class="layout">
    <div class="header">
      <div class="header-heading">
        <router-link class="header-back" :to="{ name: 'History' }">
          <img :src="icons.arrowLeft" alt="arrow" />
        </router-link>

        <a-typography-title :level="3">
          <a-typography-text type="secondary">
            {{$t('history-page.voyage audit report')}}
          </a-typography-text>

          <a-typography-text>
            {{ state.voyage.departure_port_name }} -
            {{ state.voyage.arrival_port_name }}
          </a-typography-text>
        </a-typography-title>
      </div>

      <!-- <div class="header-actions">
        <a href="" class="export-report">Export report</a>
      </div> -->
    </div>

    <div class="layout-content">
      <a-tabs v-model:activeKey="selectedType.type" @change="changeTab">
        <a-tab-pane key="weather" :tab="$t('history-page.weather factor')">
          <div class="layout-inner">
            <VoyageWeatherFactor :voyage="state.voyage" />
          </div>
        </a-tab-pane>

        <a-tab-pane key="events" :tab="$t('history-page.events')">
          <VoyageEvents />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  watchEffect,
} from "vue";
import { useRouter, useRoute } from "vue-router";

import { VoyageEvents, VoyageWeatherFactor } from "./components";

import useVoyages from "@/composables/useVoyages";
import { icons } from "@/assets";

const data = [...Array(25)].map(() => ({
  date: "12 Oct 2021 12:00 UTC",
  notification: "Departure report",
}));

export default defineComponent({
  name: "Voyage",

  setup() {
    const route = useRoute();
    const router = useRouter();

    const selectedType = reactive({ type: route.params.tab || "weather" });

    const columns = ref([
      {
        title: "Date time",
        key: "date",
        dataIndex: "date",
        width: 280,
      },
      {
        title: "Notification",
        key: "notification",
        dataIndex: "notification",
        width: 280,
      },
      {
        title: "Comment",
        key: "comment",
      },
    ]);

    const state = reactive({ voyage: {} });
    const voyages = useVoyages();

    const voyageDetails = computed(() => voyages.voyageDetails());

    onMounted(() => {
      voyages.fetchVoyageDetails(route.params.id);
    });

    watchEffect(() => {
      selectedType.type = route.params.tab;

      if (voyageDetails.value) {
        state.voyage = voyageDetails;
      }
    });

    const changeTab = (tab) => {
      router.push({
        name: "Voyage",
        params: {
          id: route.params.id,
          filter: tab === "weather" ? "chart" : "",
          tab,
        },
      });
    };

    return {
      icons,
      columns,
      data,
      state,
      selectedType,
      changeTab,
    };
  },

  components: {
    VoyageEvents,
    VoyageWeatherFactor,
  },
});
</script>

<style lang="less" scoped>
.layout {
  &-content {
    display: flex;
    flex-direction: column;
  }

  &-inner {
    height: calc(100vh - 18.6rem);
    display: flex;
  }
}

.header {
  //height: 8.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.55rem 2.8rem 0.55rem 2.8rem;

  &-heading {
    display: flex;
    align-items: center;
    gap: 3.2rem;

    h3.ant-typography {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;

      .ant-typography-secondary {
        font-size: 1.8rem;
        font-weight: 400;
      }
    }
  }

  &-back {
    img {
      height: 1.6rem;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &-actions {
    .export-report {
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
</style>
