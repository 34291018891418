<template>
  <div class="factor">
    <div class="factor-left">

      <perfect-scrollbar>
      <div class="factor-stats factor-stats-info">
        <div class="factor-stats-details-inner">
          <div class="factor-stats-details-wrapper">
            <div class="factor-stats-title">{{$t('history-page.vessel')}}</div>

            <div class="factor-stats-details-list is-columns-direction">
              <div class="factor-stats-details-item">
                <span>{{$t('history-page.name')}}</span>
                <strong>{{ voyageReport.vessel.name }}</strong>
              </div>

              <div class="factor-stats-details-item">
                <span>{{$t('history-page.type')}}</span>
                <strong>{{ voyageReport.vessel.type }}</strong>
              </div>
            </div>
          </div>

          <div class="factor-stats-details-wrapper">
            <div class="factor-stats-title">{{$t('history-page.voyage')}}</div>

            <div class="factor-stats-details-list is-columns-direction">
              <div class="factor-stats-details-item">
                <span>{{$t('history-page.conditions')}}</span>
                <strong>{{ voyageReport.voyageInfo.conditions }}</strong>
              </div>
            </div>
          </div>

          <div class="factor-stats-details-wrapper">
            <div class="factor-stats-details-list">
              <div class="factor-stats-details-item">
                <span>{{$t('history-page.departure')}}</span>
                <strong>{{ voyageReport.voyageInfo.departure_port }}</strong>
              </div>

              <div class="factor-stats-details-item">
                <span>{{$t('history-page.arrival')}}</span>
                <strong>{{ voyageReport.voyageInfo.arrival_port }}</strong>
              </div>

              <div class="factor-stats-details-item">
                <span>{{$t('history-page.distance')}}</span>
                <strong>{{ voyageReport.voyageInfo.distance }} {{$t('units.NM')}}</strong>
              </div>

              <div class="factor-stats-details-item">
                <span>{{$t('history-page.ATD')}}</span>
                <strong>
                  {{
                    voyageReport.voyageInfo.atd
                      ? date.formatFromUnixUTC(
                          voyageReport.voyageInfo.atd,
                          "dd MMM yyyy hh:mm"
                        )
                      : ""
                  }}
                  UTC
                </strong>
              </div>

              <div class="factor-stats-details-item">
                <span>{{$t('history-page.ATA')}}</span>
                <strong>
                  {{
                    voyageReport.voyageInfo.ata
                      ? date.formatFromUnixUTC(
                          voyageReport.voyageInfo.ata,
                          "dd MMM yyyy hh:mm"
                        )
                      : ""
                  }}
                  UTC
                </strong>
              </div>

              <div class="factor-stats-details-item">
                <span>{{$t('history-page.duration')}}</span>
                <strong>{{
                  voyageReport.voyageInfo.duration
                    ? convertMinutes(voyageReport.voyageInfo.duration)
                    : ""
                }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="factor-stats factor-stats-summary">
        <div class="factor-stats-title">{{$t('history-page.weather factor summary')}}</div>

        <div class="factor-stats-table">
          <a-table
            class="table-factor"
            :dataSource="voyageReport.weatherFactors"
            :columns="columns"
            :pagination="false"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'distance'">
                {{
                  record.distance !== "N/A"
                    ? record.distance.toFixed(2)
                    : record.distance
                }}
              </template>

              <template v-if="column.dataIndex === 'time'">
                {{
                  record.time !== "N/A" ? record.time.toFixed(2) : record.time
                }}
              </template>

              <template v-if="column.dataIndex === 'avg_speed'">
                {{
                  record.avg_speed !== "N/A"
                    ? record.avg_speed.toFixed(2)
                    : record.avg_speed
                }}
              </template>
            </template>
          </a-table>

          <div class="factor-stats-definition">
            <div class="factor-stats-title">{{$t('history-page.good weather definition')}}</div>
            <a-typography-text type="secondary">{{$t('history-page.wind')}}</a-typography-text>
            <a-typography-paragraph>{{
              voyageReport.definition?.wind
            }}</a-typography-paragraph>

            <a-typography-text type="secondary"
              >{{$t('history-page.significant wave height')}}</a-typography-text
            >
            <a-typography-paragraph>{{
              voyageReport.definition?.wave
            }}</a-typography-paragraph>
          </div>
        </div>
      </div>

      <div class="factor-stats factor-stats-optimization">
        <div class="factor-stats-title">{{$t('history-page.optimization factors')}}</div>

        <div class="factor-stats-inner">
          <div class="factor-stats-list is-background">
            <div class="factor-stats-item">
              <span>{{$t('history-page.actual STW')}},<br />kn</span>
              <strong>{{
                voyageReport.optimizationFactors?.rpm_power?.actual_stw.toFixed(
                  2
                )
              }}</strong>
            </div>

            <div class="factor-stats-item">
              <span>{{$t('history-page.optimal STW')}},<br />kn</span>
              <strong>{{
                voyageReport.optimizationFactors?.jit_arrival?.optimal_stw.toFixed(
                  2
                )
              }}</strong>
            </div>
          </div>

          <div class="factor-stats-wrapper">
            <div class="factor-stats-subtitle">{{$t('history-page.RPM/power')}}</div>

            <div class="factor-stats-list">
              <div class="factor-stats-item">
                <span>{{$t('history-page.route duration')}},<br />{{$t('units.hrs')}}</span>
                <strong>{{
                  voyageReport.optimizationFactors?.rpm_power?.route_duration.toFixed(
                    2
                  )
                }}</strong>
              </div>

              <div class="factor-stats-item text-block">
                <span>{{$t('history-page.modelled consumed fuel')}}, {{$t('units.tons')}}</span>
                <strong>{{
                  voyageReport.optimizationFactors?.rpm_power?.consumed_fuel_t.toFixed(
                    2
                  )
                }}</strong>
              </div>

              <div class="factor-stats-item text-block">
                <span>{{$t('history-page.potential saved fuel')}}, {{$t('units.tons')}}</span>
                <strong>{{
                  voyageReport.optimizationFactors?.rpm_power?.saved_fuel_t.toFixed(
                    2
                  )
                }}</strong>
              </div>

              <div class="factor-stats-item text-block">
                <span>{{$t('history-page.potential saved fuel')}}, %</span>
                <strong>{{
                  voyageReport.optimizationFactors?.rpm_power?.saved_fuel_pc.toFixed(
                    2
                  )
                }}</strong>
              </div>
            </div>

            <div class="factor-stats-subtitle">{{$t('history-page.JIT arrival')}}</div>

            <div class="factor-stats-list">
              <div class="factor-stats-item">
                <span>{{$t('history-page.idle period duration')}},<br />{{$t('units.hrs')}}</span>
                <strong>{{
                  voyageReport.optimizationFactors?.jit_arrival?._duration.toFixed(
                    2
                  )
                }}</strong>
              </div>

              <div class="factor-stats-item text-block">
                <span>{{$t('history-page.modelled consumed fuel')}}, {{$t('units.tons')}}</span>
                <strong>{{
                  voyageReport.optimizationFactors?.jit_arrival?.consumed_fuel_t.toFixed(
                    2
                  )
                }}</strong>
              </div>

              <div class="factor-stats-item text-block">
                <span>{{$t('history-page.potential saved fuel')}}, {{$t('units.tons')}}</span>
                <strong>{{
                  voyageReport.optimizationFactors?.jit_arrival?.saved_fuel_t.toFixed(
                    2
                  )
                }}</strong>
              </div>

              <div class="factor-stats-item text-block">
                <span>{{$t('history-page.potential saved fuel')}}, %</span>
                <strong>{{
                  voyageReport.optimizationFactors?.jit_arrival?.saved_fuel_pc.toFixed(
                    2
                  )
                }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      </perfect-scrollbar>
    </div>

    <div class="factor-right">
      <div class="factor-nav">
        <a
          href=""
          :class="['factor-nav-tab', { 'is-active': navTabActive === 'chart' }]"
          @click.prevent="handleChangeTab('chart')"
        >
          <img :src="icons.chart" alt="icon-chart" />
        </a>

        <a
          href=""
          :class="[
            'factor-nav-tab',
            { 'is-active': navTabActive === 'chart-map' },
          ]"
          @click.prevent="handleChangeTab('chart-map')"
        >
          <img :src="icons.chart" alt="icon-chart" />
          <span>+</span>
          <img :src="icons.map" alt="icon-map" />
        </a>

        <a
          href=""
          :class="['factor-nav-tab', { 'is-active': navTabActive === 'map' }]"
          @click.prevent="handleChangeTab('map')"
        >
          <img :src="icons.map" alt="icon-map" />
        </a>
      </div>

      <div
        :class="[
          'factor-map',
          {
            'is-half': navTabActive === 'chart-map',
            'is-full': navTabActive === 'map',
          },
        ]"
      >
        <div id="weather-factor-map-container" />
      </div>

      <div
        :class="['factor-chart', { 'is-half': navTabActive === 'chart-map' }]"
        v-if="navTabActive === 'chart' || navTabActive === 'chart-map'"
      >
        <vue-echarts
          ref="chartWindRef"
          v-if="voyage.speed_profile"
          :option="chartOptionWind"
          :style="`height: 100%`"
          autoresize
        />
      </div>

      <div
        :class="['factor-chart', { 'is-half': navTabActive === 'chart-map' }]"
        v-if="navTabActive === 'chart' || navTabActive === 'chart-map'"
      >
        <vue-echarts
          ref="chartSTWRef"
          v-if="voyage.speed_profile"
          :option="chartOptionSTW"
          :style="`height: 100%`"
          autoresize
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  watchEffect,
  ref,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { VueEcharts } from "vue3-echarts";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { icons } from "@/assets";
import { date } from "@/helpers";

export default defineComponent({
  name: "VoyageWeatherFactor",

  props: {
    voyage: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const { t } = useI18n();

    const navTabActive = ref(route.params.filter || "chart");

    const chartWindRef = ref(null);
    const chartSTWRef = ref(null);

    const state = reactive({
      map: null,
      report: {
        vessel: {},
        voyageInfo: {},
        weatherFactors: [],
        optimizationFactors: {},
      },
      optionWind: {
        grid: {
          top: "20%",
          left: "4%",
          right: "2%",
          bottom: "15%",
        },
        title: {
          text: t('history-page.wind speed'),  
          top: "2.5%",
          left: 10,
          textStyle: {
            color: "#1A1D21",
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 19,
          },
        },
        legend: {
          right: 0,
          top: "2.5%",
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            width: 1,
          },
          textStyle: {
            color: "#6D7379",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 17,
          },
          data: [
            {
              name: "Wind Speed",
              lineStyle: {
                color: "linearGradient(0deg, #F03945, #47BF2F)",

              },
            },
            {
              name: "Good weather limit",
              lineStyle: {
                type: "dashed",
                color: "#DEDE3B",
              },
            },
          ],
        },
        xAxis: {
          boundaryGap: true,
          axisLabel: {
            show: true,
            color: "#6D7379",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 17,
            verticalAlign: "top",
            align: "left",
            formatter: (value) => `${format(new Date(value), "dd MMM")}`,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            onZero: false,
          },
        },
        yAxis: {
          boundaryGap: true,
          axisLine: { onZero: false },
          axisLabel: {
            show: true,
            color: "#6D7379",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 17,
          },
          splitLine: {
            lineStyle: {
              color: "#CFD1D4",
            },
          },
        },
      },
      optionSTW: {
        grid: {
          top: "20%",
          left: "4%",
          right: "2%",
          bottom: "15%",
        },
        title: {
          text: t('history-page.actual STW vs optimal STW'),
          top: "2.5%",
          left: 10,
          textStyle: {
            color: "#1A1D21",
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 19,
          },
        },
        legend: {
          right: 0,
          top: "2.5%",
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            width: 1,
          },
          textStyle: {
            color: "#6D7379",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 17,
            width: 200,
            overflow: "truncate",
          },
          data: [
            {
              name: "Main route Actual STG, kn",
            },
            {
              name: "RPM optimal STG",
              lineStyle: {
                type: "dashed",
              },
            },
            {
              name: "RPM and demurrage optimal STG",
              lineStyle: {
                type: "dashed",
              },
            },
          ],
        },

        xAxis: {
          axisLabel: {
            show: true,
            color: "#6D7379",
            fontSize: 12,
            formatter: (value) => `${format(new Date(value), "dd MMM")}`,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          boundaryGap: true,
          axisLine: { onZero: false },
          axisLabel: {
            show: true,
            color: "#6D7379",
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              color: "#CFD1D4",
            },
          },
        },
      },
    });

    const voyageReport = computed(() => state.report);
    const chartOptionWind = computed(() => state.optionWind);
    const chartOptionSTW = computed(() => state.optionSTW);

    const generateChartWind = (chartData) => {
      state.optionWind.xAxis.data = chartData.wind.map((item) => [
        date.formatFromUnixUTC(item[0], "dd MMM yyyy HH:mm:ss"),
      ]);

      state.optionWind.series = [
        {
          type: "line",
          name: "Good weather limit",
          symbolSize: 0,
          lineStyle: {
            color: "#A6A600",
            type: "dashed",
            width: 1,
          },
          data: [
            [0, chartData.wind_threshold[0][1]],
            [
              date.formatFromUnixUTC(
                chartData.wind_threshold[1][0],
                "dd MMM yyyy HH:mm:ss"
              ),
              chartData.wind_threshold[0][1],
            ],
          ],
        },
        {
          type: "line",
          name: "Wind Speed",
          symbolSize: 4,
          symbol: "circle",
          data: chartData.wind.map((item) => item[1]),
        },
      ];

      state.optionWind.visualMap = {
        right: -100,
        pieces: [
          {
            gt: 0,
            lte: chartData.wind_threshold[0][1],
            color: "#47BF2F",
          },
          {
            gt: chartData.wind_threshold[0][1],
            color: "#F03945",
          },
        ],
      };

      if (chartWindRef.value) {
        chartWindRef.value.refreshOption();
      }
    };

    const generateChartSTW = (speed_profiles) => {
      const fullRoutes = speed_profiles.actual_full_route;

      const xAxisData = fullRoutes.map((item) => [
        date.formatFromUnixUTC(item[0], "dd MMM yyyy HH:mm:ss"),
      ]);

      const demurrageStart = speed_profiles.actual_demurrage.length
        ? date.formatFromUnixUTC(
            speed_profiles.actual_demurrage[0][0],
            "dd MMM yyyy HH:mm:ss"
          )
        : null;

      const demurrageFinish = speed_profiles.actual_demurrage.length
        ? date.formatFromUnixUTC(
            speed_profiles.actual_demurrage[
              speed_profiles.actual_demurrage.length - 1
            ][0],
            "dd MMM yyyy HH:mm:ss"
          )
        : null;

      state.optionSTW.xAxis.data = xAxisData;

      state.optionSTW.series = [
        {
          type: "line",
          name: "RPM optimal STG",
          symbolSize: 0,
          lineStyle: {
            color: "#E337FF",
            type: "dashed",
            width: 1,
          },
          data: [
            [0, speed_profiles.optimal_by_rpm[0][1]],
            [
              date.formatFromUnixUTC(
                speed_profiles.optimal_by_rpm[1][0],
                "dd MMM yyyy HH:mm:ss"
              ),
              speed_profiles.optimal_by_rpm[0][1],
            ],
          ],
        },
        {
          type: "line",
          name: "RPM and demurrage optimal STG",
          symbolSize: 0,
          lineStyle: {
            color: "#30CF21",
            type: "dashed",
            width: 1,
          },
          data: [
            [0, speed_profiles.optimal_by_jit[0][1]],
            [
              date.formatFromUnixUTC(
                speed_profiles.optimal_by_jit[1][0],
                "dd MMM yyyy HH:mm:ss"
              ),
              speed_profiles.optimal_by_jit[0][1],
            ],
          ],
        },
        {
          type: "line",
          name: "Main route Actual STG, kn",
          symbolSize: 4,
          symbol: "circle",
          color: "#60B6E7",
          markArea:
            demurrageStart && demurrageFinish
              ? {
                  itemStyle: {
                    color: "rgba(255, 185, 81, 0.3)",
                  },
                  data: [
                    [
                      {
                        xAxis: demurrageStart,
                      },
                      {
                        xAxis: demurrageFinish,
                      },
                    ],
                  ],
                }
              : {},
          data: fullRoutes.map((item) => item[1]),
        },
      ];

      if (demurrageStart && demurrageFinish) {
        state.optionSTW.visualMap = {
          right: -100,
          dimension: 0,
          pieces: [
            {
              gt: 0,
              lte: speed_profiles.actual_main_route.length,
              color: "#60B6E7",
            },
            {
              gt: speed_profiles.actual_main_route.length,
              lte: fullRoutes.length,
              color: "#F0A739",
            },
          ],
        };
      }

      if (chartSTWRef.value) {
        chartSTWRef.value.refreshOption();
      }
    };

    const convertMinutes = (count) => {
      const days = Math.floor(count / 1440);
      const hours = Math.floor((count - days * 1440) / 60);
      const minutes = Math.round(count % 60);

      if (days > 0) {
        return `${days} d ${hours} h ${minutes} m`;
      } else {
        return `${hours} h ${minutes} m`;
      }
    };

    const handleChangeTab = (tab) => {
      navTabActive.value = tab;

      router.push({
        name: "Voyage",
        params: { id: route.params.id, tab: route.params.tab, filter: tab },
      });
    };

    const intitialContentTab = async (tab) => {
      const { features } = props.voyage.voyage_maps;
      const coordinates = features.map((item) => item.geometry.coordinates);


      const result = [];
      let lastElement = 0;
      let resultCount = [];

      for (let i = 0; i < features.length; i++) {
        const el = features[i].properties.weight;

        if (lastElement === 0) {
          lastElement = el;
        } else if (
          (el > 4 && lastElement <= 4) ||
          (el <= 4 && lastElement > 4)
        ) {
          resultCount.push(features[i].geometry.coordinates);

          result.push({
            type: lastElement > 4 ? "#F03945" : "#81D862",
            data: resultCount,
          });

          resultCount = [];
          lastElement = el;
        }

        resultCount.push(features[i].geometry.coordinates);
      }

      if (resultCount.length) {
        result.push({
          type: lastElement > 4 ? "#F03945" : "#47BF2F",
          data: resultCount,
        });
      }

      if (!state.map && tab !== "chart") {
        state.map = new mapboxgl.Map({
          container: "weather-factor-map-container",
          style: process.env.VUE_APP_MAPBOX_STYLE,
          center: coordinates[0],
          zoom: 6,
        });

        state.map.on("load", () => {
          state.map.addSource("points", {
            type: "geojson",
            lineMetrics: true,
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: coordinates,
              },
            },
          });

          state.map.addSource("directionPoints", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [
                  coordinates[0],
                  coordinates[coordinates.length - 1],
                ],
              },
            },
          });

          result.map((item, i) => {
            state.map.addLayer({
              id: `points-${i}`,
              type: "line",
              source: {
                type: "geojson",
                lineMetrics: true,
                data: {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: item.data,
                  },
                },
              },
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": item.type,
                "line-width": 2,
              },
            });
          });

          state.map.addLayer({
            id: "directionPoints",
            type: "circle",
            source: "directionPoints",
            paint: {
              "circle-color": "transparent",
              "circle-radius": 5,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#47BF2F",
            },
          });
        });
      }

      await nextTick();

      if (state.map) {
        state.map.resize();

        state.map.fitBounds(
          [coordinates[0], coordinates[coordinates.length - 1]],
          { padding: 50 }
        );

        state.map.resize();
      }
    };

    watchEffect(() => {
      navTabActive.value = route.params.filter || "chart";

      if (props.voyage.voyage_report) {
        const { speed_profiles, weather_factors: chartWeatherFactors } =
          props.voyage.speed_profile;

        const { vessel, weather_factors, voyage_info, optimization_factors } =
          props.voyage.voyage_report;

        state.report = {
          voyageInfo: voyage_info,
          weatherFactors: [
            {
              period: t('history-page.entire period'),
              ...weather_factors?.entire_period,
            },
            {
              period: t('history-page.good weather'),
              ...weather_factors?.good_weather,
            },
            {
              period: t('history-page.adverse weather'),
              ...weather_factors?.adverse_weather,
            },
          ],
          definition: weather_factors?.definition,
          optimizationFactors: optimization_factors,
          vessel,
        };

        generateChartWind(chartWeatherFactors);
        generateChartSTW(speed_profiles);

        if (route.params.tab === "weather") {
          intitialContentTab(navTabActive.value);
        }
      }
    });

    return {
      date,
      icons,
      navTabActive,
      voyageReport,
      chartOptionWind,
      chartOptionSTW,
      format,
      convertMinutes,
      intitialContentTab,
      handleChangeTab,
      columns: [
        {
          title: t('history-page.period'),
          dataIndex: "period",
          key: "period",
        },
        {
          title: t('history-page.distance') + "," + t('units.NM'),
          dataIndex: "distance",
          key: "distance",
          align: "right",
        },
        {
          title: t('history-page.time') + "," + t('units.hr'),
          dataIndex: "time",
          key: "time",
          align: "right",
        },
        {
          title: t('history-page.avg speed') + "," + t('units.kn'),
          dataIndex: "avg_speed",
          key: "avg_speed",
          align: "right",
        },
      ],
    };
  },

  components: {
    VueEcharts,
  },
});
</script>

<style lang="less" scoped>
.ps {
  height: 100%;
}
.factor {
  width: 100%;
  display: flex;

  &-left {
    width: 50%;
    background: @color-white;
    //overflow: scroll;
    //border-right: 0.1rem solid #1b1b1e;
  }

  &-right {
    width: 50%;
    position: relative;
  }

  &-map {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;

    #weather-factor-map-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.is-half {
      height: 46%;
    }

    &.is-full {
      height: 100%;
    }
  }

  &-nav {
    height: 4.8rem;
    background: @color-white-panel;
    box-shadow: 0px 2px 4px rgba(@color-dark 0.1);
    //border-left: 0.1rem solid #1b1b1e;
    border-bottom: 0.1rem solid @color-border;
    display: flex;
    align-items: center;
    margin-top: -4.8rem;
    padding: 1rem 2.4rem;
    position: relative;
    z-index: 2;

    &-tab {
      height: 2.8rem;
      border: 0.1rem solid #818699;
      color: @color-secondary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding: 0 2rem;
      gap: 0.6rem;
      margin-left: -0.1rem;

      &.is-active {
        background-color: @color-primary;
        border-color: @color-primary;
        color: @color-white;

        img {
          filter: brightness(0) invert(1);
        }
      }

      &:first-child {
        border-radius: 0px;
      }

      &:last-child {
        border-radius: 0px;
      }

      img {
        width: 2rem;
        height: 1.8rem;
      }
    }
  }

  &-stats {
    width: 100%;
    padding: 2.5rem;

    &-info {
      background-color: @color-white;
      box-shadow: 0 0.2rem 0.4rem rgba(@color-dark, 0.1);
      //height: 25%;
      height: 23.0rem;
    }

    &-summary {
      //height: 35%;
      height: 35.0rem;
      border-bottom: 0.1rem solid @color-border;
    }

    &-optimization {
      height: 40.0rem;
      //height: 40%;
    }

    &-title {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 1.0rem;
    }

    &-subtitle {
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 2.2rem;
    }

    &-inner {
      display: flex;
      gap: 2.8rem;
    }

    &-wrapper {
      flex: 1;
    }

    &-list {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 4rem;
      }

      &.is-background {
        background-color: @color-body;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2.4rem 2.8rem;
        margin-bottom: 0;

        .factor-stats-item {
          flex: initial;
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      flex: 1;

      span {
        line-height: 1.8rem;
        color: @color-secondary;
        margin-bottom: 0.6rem;
      }

      strong {
        font-size: 2.4rem;
        font-weight: 400;
      }
    }

    &-table {
      display: flex;
      gap: 5.2rem;
    }

    &-definition {
      border: 0.1rem solid @color-border;
      padding: 2rem;
      position: relative;

      &:after {
        content: "";
        width: 3.5rem;
        height: 3.5rem;
        border-top: 1.8rem solid @color-border;
        border-right: 1.8rem solid @color-border;
        border-left: 1.8rem solid transparent;
        border-bottom: 1.8rem solid transparent;
        position: absolute;
        top: -0.1rem;
        left: -3.6rem;
      }

      &:before {
        content: "";
        width: 3.3rem;
        height: 3.4rem;
        border-top: 1.7rem solid @color-white;
        border-right: 1.7rem solid @color-white;
        border-left: 1.7rem solid transparent;
        border-bottom: 1.7rem solid transparent;
        position: absolute;
        top: 0;
        left: -3.4rem;
        z-index: 1;
      }

      div.ant-typography {
        font-size: 1.6rem;
        margin-top: 0.4rem;
      }
    }

    &-details-inner {
      display: flex;
      gap: 6rem;
    }

    &-details-wrapper {
      .factor-stats-title {
        margin-bottom: 1.4rem;
      }
    }

    &-details-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: 4.2rem;

      &.is-columns-direction {
        flex-direction: column;
        margin-top: 0;

        .factor-stats-details-item {
          width: 100%;
          white-space: nowrap;
        }
      }
    }

    &-details-item {
      width: calc(100% / 3 - 2rem);
      display: flex;
      flex-direction: column;

      span {
        line-height: 1.8rem;
        color: @color-secondary;
        margin-bottom: 0.4rem;
      }

      strong {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
  }

  &-chart {
    width: 100%;
    height: 50%;
    padding: 1%;

    &.is-half {
      height: 27%;
      //padding: 10rem;
    }
  }
}
</style>
