import { useStore } from 'vuex';
import axios from 'axios';
import queryString from 'query-string';

export default () => {
  const store = useStore();

  const fetchNoonReports = (query) => {
    const queryParams = queryString.stringify(query);

    return axios.get(`/api/noon_reports?${queryParams}`).then(({ data }) => {
      if (query) {
        store.commit('reports/eventsNoonReports', data);
      } else {
        store.commit('reports/noonReports', data);
      }
    });
  };

  const noonReports = () => {
    return store.state.reports.noonReports;
  };

  const eventsNoonReports = () => {
    return store.state.reports.eventsNoonReports;
  };

  return {
    fetchNoonReports,
    noonReports,
    eventsNoonReports,
  };
};
