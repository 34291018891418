<template>
  <div class="layout">
    <ModalNoonReport
      :visible="modalVisible"
      :onCancel="() => handleOpenNoonReport(false)"
    />

    <div id="events-map-container" class="layout-map" />
  </div>
</template>

<script>
import {
  defineComponent,
  watch,
  reactive,
  onMounted,
  nextTick,
  ref,
} from "vue";
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

export default defineComponent({
  name: "VoyageEventsMap",

  emits: ["point-hover"],

  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    hoverIndex: {
      type: Number,
      default: null,
    },
  },

  setup(props, { emit }) {
    const modalVisible = ref(false);

    const stateMap = reactive({
      map: null,
      selectedIndex: 0,
      hoverIndex: null,
    });

    onMounted(() => {
      initialMap();
    });

    watch(props, () => {
      if (stateMap.selectedIndex !== props.tabIndex) {
        stateMap.map.setPaintProperty(
          `point-${stateMap.selectedIndex}`,
          "circle-color",
          "#587AFF"
        );

        stateMap.map.setPaintProperty(
          `point-${stateMap.selectedIndex}`,
          "circle-stroke-color",
          "#587AFF"
        );

        stateMap.map.setPaintProperty(
          `point-${stateMap.selectedIndex}`,
          "circle-radius",
          4
        );

        setPaintPropertiesToSelected(props.tabIndex);

        stateMap.selectedIndex = props.tabIndex;
      }

      if (stateMap.hoverIndex !== props.hoverIndex) {
        if (
          stateMap.hoverIndex !== null &&
          stateMap.hoverIndex !== stateMap.selectedIndex
        ) {
          stateMap.map.setPaintProperty(
            `point-${stateMap.hoverIndex}`,
            "circle-color",
            "#587AFF"
          );

          stateMap.map.setPaintProperty(
            `point-${stateMap.hoverIndex}`,
            "circle-stroke-color",
            "#587AFF"
          );
        }

        if (props.hoverIndex !== null) {
          stateMap.map.setPaintProperty(
            `point-${props.hoverIndex}`,
            "circle-color",
            "#F03945"
          );

          stateMap.map.setPaintProperty(
            `point-${props.hoverIndex}`,
            "circle-stroke-color",
            "#F03945"
          );
        }

        stateMap.hoverIndex = props.hoverIndex;
      }
    });

    const setPaintPropertiesToSelected = (index) => {
      stateMap.map.setPaintProperty(
        `point-${index}`,
        "circle-color",
        "#F03945"
      );

      stateMap.map.setPaintProperty(
        `point-${index}`,
        "circle-stroke-color",
        "#F03945"
      );

      stateMap.map.setPaintProperty(`point-${index}`, "circle-radius", 8);
    };

    const initialMap = async () => {
      const coordinates = props.dataSource.map((item) => [
        item.position_convert[1],
        item.position_convert[0],
      ]);

      stateMap.map = new mapboxgl.Map({
        container: "events-map-container",
        style: process.env.VUE_APP_MAPBOX_STYLE,
        center: coordinates[0],
        zoom: 3,
      });

      stateMap.map.on("load", () => {
        stateMap.map.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: coordinates,
            },
          },
        });

        stateMap.map.addSource("directionPoints", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [
                coordinates[0],
                coordinates[coordinates.length - 1],
              ],
            },
          },
        });

        stateMap.map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#587AFF",
            "line-width": 2,
            "line-dasharray": [1, 3],
          },
        });

        stateMap.map.addLayer({
          id: "directionPoints",
          type: "circle",
          source: "directionPoints",
          paint: {
            "circle-color": "transparent",
            "circle-radius": 4,
            "circle-stroke-width": 2,
            "circle-stroke-color": "#587AFF",
          },
        });

        coordinates.map((point, i) => {
          const pointId = `point-${i}`;

          stateMap.map.addLayer({
            id: pointId,
            type: "circle",
            source: {
              type: "geojson",
              lineMetrics: true,
              data: {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: point,
                },
              },
            },
            paint: {
              "circle-color": "#587AFF",
              "circle-radius": 4,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#587AFF",
            },
          });

          stateMap.map.on("click", pointId, () => {
            handleOpenNoonReport(true);
          });

          stateMap.map.on("mouseenter", pointId, () => {
            stateMap.map.getCanvas().style.cursor = "pointer";
            emit("point-hover", { pointId: i });
          });

          stateMap.map.on("mouseleave", pointId, () => {
            stateMap.map.getCanvas().style.cursor = "";
          });
        });

        setPaintPropertiesToSelected(props.tabIndex);
      });

      await nextTick();

      stateMap.map.resize();

      stateMap.map.fitBounds(
        [coordinates[coordinates.length - 1], coordinates[0]],
        { padding: 150 }
      );

      stateMap.map.resize();
    };

    const handleOpenNoonReport = (status) => {
      modalVisible.value = status;
    };

    return {
      stateMap,
      handleOpenNoonReport,
      modalVisible,
    };
  },
});
</script>

<style lang="less" scoped>
.layout {
  width: 100%;

  &-map {
    width: 100%;
    height: 100%;
  }
}
</style>
