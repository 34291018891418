<template>
  <div class="events">
    <ModalNoonReport
      :visible="modalVisible"
      :onCancel="() => handleOpenNoonReport(false)"
    />

    <div class="events-wrapper">
      <perfect-scrollbar>
        <div class="events-header">
          <a-typography-text type="secondary">{{$t('history-page.date time')}}</a-typography-text>
          <a-typography-text type="secondary">{{$t('history-page.notification')}}</a-typography-text>
        </div>

        <div class="events-collapse">
          <a-collapse
            v-model:activeKey="stateReports.selectedIndex"
            :bordered="false"
            @change="handleChangePanel"
            accordion
          >
            <a-collapse-panel
              v-for="(item, index) in stateReports.list"
              @mouseover="handleHoverPanel(index)"
              @mouseleave="handleHoverPanel(null)"
              :key="index"
            >
              <template #header>
                <div class="events-collapse-item-header">
                  <a-typography-text type="secondary">
                    {{
                      item.date_report
                        ? date.formatUTC(item.date_report, "dd MMM yyyy, HH:mm")
                        : ""
                    }}
                    UTC
                  </a-typography-text>

                  <a-typography-text>Noon report</a-typography-text>
                </div>
              </template>

              <div class="events-collapse-item-body">
                <ul class="events-collapse-list">
                  <li>
                    <a-typography-text type="secondary">COG</a-typography-text>
                    <a-typography-text>{{ item.cog }}°</a-typography-text>
                  </li>

                  <li>
                    <a-typography-text type="secondary">SOG</a-typography-text>
                    <a-typography-text>{{ item.sog }} kn</a-typography-text>
                  </li>

                  <li>
                    <a-typography-text type="secondary"
                      >Position</a-typography-text
                    >
                    <a-typography-text>
                      <span>
                        {{ convertation.convertToDms(item.position_convert[0])
                        }}<br />
                        {{
                          convertation.convertToDms(
                            item.position_convert[1],
                            true
                          )
                        }}
                      </span>
                    </a-typography-text>
                  </li>
                </ul>

                <a class="open-report" @click="handleOpenNoonReport(true)"
                  >Open noon report</a
                >
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </perfect-scrollbar>
    </div>

    <VoyageEventsMap
      v-if="stateReports.list.length"
      :dataSource="stateReports.list"
      :tabIndex="stateReports.selectedIndex"
      :hoverIndex="stateReports.hoverIndex"
      @point-hover="handleChangePointHover"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  watchEffect,
  onMounted,
  ref,
} from "vue";

import useReports from "@/composables/useReports";

import { date, convertation } from "@/helpers";

import VoyageEventsMap from "./VoyageEventsMap.vue";

export default defineComponent({
  name: "VoyageEvents",

  setup() {
    const modalVisible = ref(false);

    const stateReports = reactive({
      selectedIndex: 0,
      hoverIndex: null,
      list: [],
    });

    const reports = useReports();
    const reportsList = computed(() => reports.eventsNoonReports());

    onMounted(() => {
      reports.fetchNoonReports({
        group_id: 6,
      });
    });

    watchEffect(() => {
      if (!stateReports.list.length) {
        stateReports.list = reportsList.value;
      }
    });

    const handleChangePanel = (key) => {
      stateReports.selectedIndex = +key;
    };

    const handleHoverPanel = (key) => {
      stateReports.hoverIndex = key !== null ? +key : null;
    };

    const handleOpenNoonReport = (status) => {
      modalVisible.value = status;
    };

    const handleChangePointHover = ({ pointId }) => {
      stateReports.selectedIndex = pointId;
    };

    return {
      date,
      convertation,
      modalVisible,
      stateReports,
      handleChangePanel,
      handleHoverPanel,
      handleOpenNoonReport,
      handleChangePointHover,
    };
  },

  components: {
    VoyageEventsMap,
  },
});
</script>

<style lang="less" scoped>
.events {
  height: calc(100vh - 18.6rem);
  display: flex;

  &-wrapper {
    min-width: 43.5rem;
    background-color: @color-white-panel;
    border-right: 0.1rem solid @color-border;
  }

  .ps {
    height: 100%;
  }

  &-header {
    border-bottom: 0.1rem solid @color-border;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    padding: 2.4rem 0.8rem 1.2rem 4rem;

    .ant-typography {
      width: 18rem;
    }
  }

  .events-collapse {
    &:deep(.ant-collapse) {
      background: transparent;
      border-radius: 0px;

      &-arrow {
        color: @color-secondary;
      }

      &-item {
        .ant-collapse-header {
          &:hover {
            background: rgba(@color-primary, 0.1);
          }
        }

        &-active {
          &:hover {
            .ant-collapse-header {
              background: rgba(@color-primary, 0.095);
            }
          }

          .ant-collapse-header {
            background: rgba(@color-primary, 0.095);
            font-weight: 700;
          }

          .ant-collapse-arrow {
            color: @color-white;
          }
        }
      }

      &-content {
        background: rgba(@color-primary, 0.095) !important;
        border-color: transparent;
      }
    }

    &-item-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.6rem;

      .ant-typography-secondary {
        width: 19rem;
      }

      .ant-typography:not(.ant-typography-secondary) {
        width: 17rem;
      }
    }

    &-item-body {
      padding: 0 2.4rem 1rem 2.4rem;
    }

    &-list {
      display: flex;
      list-style: none;
      margin-bottom: 3.5rem;
      padding: 0;
      gap: 2.8rem;

      li {
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;

        .ant-typography:not(.ant-typography-secondary) {
          line-height: 1;
          font-size: 2.4rem;

          span {
            font-size: 1.6rem;
          }
        }
      }
    }

    .open-report {
      width: 100%;
      background: #444651;
      border-radius: 0px;
      color: @color-white;
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 600;
      padding: 0.8rem 0;
      text-align: center;

      &:hover {
        background: lighten(#444651, 5%);
      }
    }
  }
}
</style>
