import { useStore } from 'vuex';
import axios from 'axios';

export default () => {
  const store = useStore();

  const fetchVoyageDetails = (id) => {
    return axios.get(`/api/voyages/${id}`).then(({ data }) => {
      store.commit('voyages/setDetails', data);
    });
  };

  const fetchVoyages = () => {
    return axios.get('/api/voyages').then(({ data }) => {
      store.commit('voyages/setList', data);
    });
  };

  const voyages = () => {
    return store.state.voyages.list;
  };

  const voyageDetails = () => {
    return store.state.voyages.details;
  };

  return {
    fetchVoyages,
    fetchVoyageDetails,
    voyages,
    voyageDetails,
  };
};
